<template>
    <section class="fullscreen d-flex justfy-content-center">
        <div class="container align-self-center">
            <div class="row text-center">
                <div class="col-12">
                    <h1>Altura Leiden Holding BV</h1>
                    <ul>
                        <li>Pascalstraat 12 A</li>
                        <li>3771 RT Barneveld</li>
                        <li><a href="mailto:info@altura-leiden.nl">info@altura-leiden.nl</a></li>
                    </ul>
                    <a class="btn mx-auto" href="mailto:info@altura-leiden.nl">Stuur e-mail</a>
                </div>
            </div>
        </div>
    </section>
</template>
